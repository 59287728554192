<template>
  <loading v-if="is_loading" />
  <div v-else class="profile">
    <b-row v-if="pageData">
      <!-- right-side -->
      <b-col sm="12" md="9">
        <b-card body-class="pb-0" :img-src="filterImages(pageData.media, 'cover_image')" :img-alt="pageData.first_name"
          img-top>
          <b-avatar class="my-avatar" size="10rem" :src="filterImages(pageData.media, 'path')"
            :img-alt="pageData.first_name" />
          <b-card-title class="font-weight-bold" style="font-size: 28px">
            {{ pageData.first_name }}
          </b-card-title>
          <b-row align-h="between">
            <b-col>
              <b-card-sub-title class="mt-0 mb-1 text-capitalize">
                {{ $t(pageData.user_type) }}
              </b-card-sub-title>
            </b-col>
            <b-col cols="auto">
              <b-link to="/edit-profile">
                <b-button :disabled="is_loading" variant="primary">
                  {{ $t("g.EditProfile") }}
                </b-button>
              </b-link>
            </b-col>
          </b-row>
          <hr />

          <!-- Start Tab Header -->
          <b-col cols="12" nav-class="mb-0 wrap">
            <div class="tab-header-scrollable">
              <b-tabs v-model="activeTab" style="overflow-x: auto">
                <b-tab v-for="(tab, index) in tabs" :key="index" :title="$t(`g.${tab.title}`)"></b-tab>
              </b-tabs>
            </div>
          </b-col>
          <!-- End Tab Header -->
        </b-card>

        <!-- Start Tab Body -->
        <b-card>
          <b-tabs v-model="activeTab" nav-class="d-none">
            <b-tab v-for="(tab, index) in tabs" :key="index">
              <div class="tab-body-title">
                <h4>
                  {{ $t(`g.${tab.title}`) }}
                </h4>
                <img :src="editSvg" alt="" v-if="isEdit && tab.title == 'About'" @click="isEdit = false"
                  style="cursor: pointer" />
                <img :src="closeSvg" alt="" v-else-if="tab.title == 'About'" @click="isEdit = true"
                  style="cursor: pointer" />
              </div>
              <hr />
              <profile-about-component v-if="tab.title == 'About'" :editable="true" :isEdit="isEdit"
                :aboutProps="componentsData.about" />
              <profile-info-component v-if="tab.title == 'Info'" :infoProps="componentsData.info" />
              <profile-teaching-component v-if="tab.title == 'Teaching'" :enrolledProps="componentsData.teaching" />
            </b-tab>
          </b-tabs>
        </b-card>
        <!-- End Tab Body -->
      </b-col>
      <!-- right-side -->

      <!-- left side -->
      <b-col sm="12" md="3">
        <b-card :title="$t('g.Account')">
          <hr />
          <b-card-sub-title class="mt-0">
            {{ $t("g.Joined") }}
          </b-card-sub-title>
          <b-card-text>{{ convertDate(pageData.created_at) }}</b-card-text>
        </b-card>
        <profile-parents-card-component v-if="parentsData" :parentsCardProps="parentsData" />
        <profile-friends-card-component v-if="friendsData" :friendsCardProps="friendsData" />
      </b-col>
      <!-- left side -->
    </b-row>
  </div>
</template>

<script>
import Loading from "@/components/loading/loading.vue";
import ProfileInfoComponent from "@/components/profile/ProfileInfoComponent";
import ProfileAboutComponent from "@/components/profile/ProfileAboutComponent";
import ProfileFriendsCardComponent from "@/components/profile/ProfileFriendsCardComponent";
import ProfileTeachingComponent from "@/components/profile/ProfileTeachingComponent";
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BCardSubTitle,
  BButton,
  BListGroupItem,
  BAvatar,
  BTabs,
  BTab,
  BCardTitle,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BTab,
    BCard,
    BLink,
    BTabs,
    BButton,
    BAvatar,
    Loading,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BListGroupItem,
    ProfileInfoComponent,
    ProfileTeachingComponent,
    ProfileAboutComponent,
    ProfileFriendsCardComponent,
  },
  data() {
    return {
      isError: false,
      is_loading: true,
      pageData: null,
      parentsData: null,
      friendsData: null,
      coursesData: null,
      activeTab: 0,
      isEdit: false,
      closeSvg: require("@/assets/images/icons/close.svg"),
      editSvg: require("@/assets/images/icons/edit.svg"),
      profileImgaePlaceholder: require("@/assets/images/placeholders/profileAvatar.png"),
      profileCoverPlaceholder: require("@/assets/images/placeholders/profileCover.png"),
      componentsData: {
        about: null,
        info: null,
        teaching: null,
      },
      tabs: [{ title: "About" }, { title: "Info" }, { title: "Teaching" }],
    };
  },
  created() {
    this.getMyProfile();
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    async getMyProfile() {
      try {
        this.is_loading = true;
        const userRes = await this.$http.get("/auth/teacher/profile");
        const data = userRes.data.data.user;
        this.pageData = data;
        this.componentsData = {
          ...this.componentsData,
          about: data.about,
          info: {
            email: data.email,
            name: data.first_name,
            phone: data.phone?.length ? data.phone[0].phone : null,
            country: data.country.name,
            birthday: data.birthdate,
          },
        };
        this.getMyCourses();
        this.getMyFriends();
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },
    async getMyCourses() {
      try {
        this.is_loading = true;
        const myCourses = await this.$http.get(
          "/teacher/courses?my_courses=true"
        );
        const data = myCourses.data.data;
        this.coursesData = data;
        this.componentsData = {
          ...this.componentsData,
          teaching: data,
        };
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },
    async getMyFriends() {
      try {
        this.is_loading = true;
        const friendsRes = await this.$http.get("/teacher/user-friends");
        this.friendsData = friendsRes.data.data;
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },
    convertDate(date) {
      // Convert the timestamp to a Date object
      const dateObject = new Date(date);

      // Desired output format
      const options = { year: "numeric", month: "short", day: "numeric" };

      // Convert the Date object to the desired output format
      const desiredOutput = dateObject.toLocaleDateString("en-US", options);

      return desiredOutput;
    },
    filterImages(data, name) {
      const placeholderImage =
        name === "path"
          ? this.profileImgaePlaceholder
          : this.profileCoverPlaceholder;
      if (data) {
        for (let image of data) {
          if (image.name === name) {
            return image.path;
          }
        }
      } else {
        return placeholderImage;
      }
      return placeholderImage;
    },
  },
};
</script>

<style lang="scss">
@import "./profile.scss";
</style>
