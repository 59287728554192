<template>
  <b-col v-if="infoProps">
    <b-row>
      <b-card-title>
        {{ $t("g.profile_info_basics") }}
      </b-card-title>
    </b-row>
    <b-row v-for="(value, key, index) in infoProps" :key="index">
      <b-card-text style="text-transform: capitalize">
        {{ $t(`g.profile_${key}`) }}
      </b-card-text>
      <b-card-text v-if="key !== 'birthday'">
        :
        {{
          value
            ? value
            : $t("g.profile_info_there_is_no") + " " + $t(`g.profile_${key}`)
        }}
      </b-card-text>
      <b-card-text v-else>
        :
        {{
          value
            ? value.replaceAll("-", "/")
            : $t(`g.profile_info_there_is_no`) + " " + $t(`g.profile_${key}`)
        }}
      </b-card-text>
    </b-row>
  </b-col>
  <div v-else>
    <b-card-text>{{ $t("g.ThereIsNoInfoForNow") }}</b-card-text>
  </div>
</template>

<script>
import { BCardText, BRow, BCol, BCardTitle } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BCardTitle,
  },
  props: ["infoProps"],
};
</script>
<style scoped>
@import "./index.scss";
</style>
