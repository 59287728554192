<template>
  <b-card class="friends-card">
    <b-card-title
      class="d-flex justify-content-between align-items-center flex-wrap"
    >
      <p class="mb-0 my-1">{{ $t("g.Friends") }}</p>
      <b-link to="/friend-requests" style="font-size: 14px; color: #135991">
        {{ $t("friend_requests") }}
      </b-link>
    </b-card-title>
    <hr />
    <div v-if="friendsCardProps.length">
      <b-row
        class="align-items-center"
        v-for="(friend, index) in friendsCardProps"
        :key="index"
      >
        <div
          class="mb-2"
          v-if="
            friend.friend &&
            friend.friend.id !== $store.state.userData.userId &&
            friend.friend.type !== 'admin'
          "
        >
          <b-link
            @click="redirectUser(friend.friend.type, friend.friend.type_id)"
          >
            <b-avatar
              :src="
                friend.friend.media && friend.friend.media.length
                  ? friend.friend.media[0].path
                  : profileImgaePlaceholder
              "
              class="mx-1"
            />
            <span class="user-name">{{
              `${friend.friend.first_name} ${friend.friend.last_name}`
            }}</span>
          </b-link>
        </div>
      </b-row>
    </div>
    <h4 v-else>
      {{ $t("g.profile/friends_card_component/no_friends") }}
    </h4>
  </b-card>
</template>

<script>
import { BCard, BRow, BAvatar, BLink, BCardTitle } from "bootstrap-vue";
export default {
  name: "ProfileFriendsCardComponent",
  components: {
    BRow,
    BCard,
    BAvatar,
    BLink,
    BCardTitle,
  },
  props: ["friendsCardProps"],
  data() {
    return {
      userId: null,
      profileImgaePlaceholder: require("@/assets/images/placeholders/profileAvatar.png"),
    };
  },
  methods: {
    redirectUser(userType, id) {
      if (userType !== "admin") {
        switch (userType) {
          case "student":
            this.$router.push(`/show-student/${id}`);
            break;
          case "guardian":
            this.$router.push(`/show-parent/${id}`);
            break;
          case "teacher":
            this.$router.push(`/show-teacher/${id}`);
            break;
          default:
            this.$router.push("/");
            break;
        }
      }
    },
    filterImages(data, name) {
      const placeholderImage =
        name === "path"
          ? this.profileImgaePlaceholder
          : this.profileCoverPlaceholder;
      if (data) {
        for (let image of data) {
          if (image.name === name) {
            return image.path;
          }
        }
      } else {
        return placeholderImage;
      }
      return placeholderImage;
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
