<template>
    <div class="d-flex flex-column" style="gap: 23px" v-if="enrolledProps">
        <b-row align-h="start" v-for="(enroll, index) in enrolledProps" :key="index">
            <b-col cols="auto">
                <img width="45" height="45" class="course-image"
                    :src="enroll.image && enroll.image.length ? enroll.image[0].path : imageSrc" :alt="enroll.name" />
            </b-col>
            <b-col>
                <b-row>{{ enroll.name }}</b-row>
                <b-row>{{ enroll.lessons_count }}</b-row>
            </b-col>
        </b-row>
        <b-link to="/class-rooms/courses">
            <div class="add-classroom d-flex justify-content-center align-items-center">
                <img :src="plusIcon" alt="Add Course" />
            </div>
        </b-link>
    </div>
    <b-card-text class="mb-0" v-else>
        {{ $t('g.ThereIsCurrentlyNoEnrolledCourses') }}
    </b-card-text>
</template>
  
<script>
import { BCardText, BLink, BRow, BCol } from 'bootstrap-vue'

export default {
    components: {
        BCol,
        BRow,
        BCardText,
        BLink
    },
    props: [
        'enrolledProps'
    ],
    data() {
        return {
            imageSrc: require('@/assets/dev/Rectangle 12.png'),
            plusIcon: require('@/assets/images/icons/plus.svg'),
        }
    },
}
</script>
<style scoped>
@import './index.scss';
</style>
  